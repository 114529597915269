import '../../App.css';
import './Premiacoes.css'
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import TextoFormatado from '../../components/TextoFormatado/TextoFormatado.jsx';
import BlueBar from '../../components/BlueBar/BlueBar.jsx';

function Premiacoes() {

  const titulo1 = 'Premiações para os artigos';
  const texto1 = `Os melhores trabalhos do ERRC 2024 receberão um certificado durante a cerimônia durante o evento.

  **•Melhor Artigo ERRC:** “Investigando as Implicações de Segurança da Engenharia de Tráfego e Conectividade no Roteamento da Internet”, Renan Barreto (FURG), Leandro Bertholdo (UFRGS) e Pedro Marcos (FURG).

  **•Melhor Artigo WRSEG:** “Otimização de hiperparâmetros da DroidAugmentor para geração de dados sintéticos de malware Android”, Angelo Gaspar Diniz Nogueira, Lucas Ferreira Areais de Oliveira, Anna Luiza Gomes da Silva, Diego Kreutz, Rodrigo Brandão Mansilha (Unipampa).
  
  **Regras de Avaliação e Seleção:**

    **•Melhor Artigo:** Os melhores artigos serão selecionados com base nas notas da avaliações via JEMS3. Os artigos melhores avaliados serão selecionados para apresentação em sessão especial no ERRC 2024. A avaliação final será realizada pelo Comitê de Avaliação e levará em conta a qualidade do artigo e também a apresentação do trabalho para a audiência.
    
  `;

  const titulo2 = 'Comitê de Avaliação:';
  const texto2 = `
Marcia Pasin (UFSM)
Roben Lunardi (IFRS)
Charles Miers (UDESC)
Thiago Ayub (Sage Networks)`
  return (
    <div className='Container-Premiacoes'>
        <BlueBar title="Premiações" />
        <TextoFormatado title={titulo1} text={texto1} />
        <TextoFormatado title={titulo2} text={texto2} />
        
    </div>
  )
}

export default Premiacoes